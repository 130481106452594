<template>
  <div style="padding-left:5px;">
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">基础数据管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/schoolStat">学校测温统计</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <el-form ref="queryForm" :model="queryModel" :rules="ruleValidate" inline class="demo-form-inline">
      <el-form-item label="上级单位" prop="parentId">
        <el-select-tree
          size="mini"
          :options="companyList"
          v-model="queryModel.parentId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="查询日期" prop="queryDate">
        <el-date-picker
          v-model="queryModel.queryDate"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="search()"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
        <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-download"
        @click="downloadCSV"
      >导出数据</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :height="tableHeight"
      stripe
    >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="name" label="学校名称" width="200"></el-table-column>
      <el-table-column prop="deviceNum" label="安装设备数" width="120"></el-table-column>
      <el-table-column prop="deviceOnlineNum" label="设备本日在线数" width="120"></el-table-column>
      <el-table-column prop="deviceNowOnlineNum" label="设备当前在线数" width="120"></el-table-column>
      <el-table-column prop="studentNum" label="学生总人数" width="120"></el-table-column>
      <el-table-column prop="detectStudentNum" label="今日已测温学生人数" width="150"></el-table-column>
      <el-table-column prop="visitorNum" label="今日访客人次" width="120"></el-table-column>
      <el-table-column prop="normalVisitorNum" label="体温正常人次" width="120"></el-table-column>
      <el-table-column prop="identityNum" label="今日已识别人数" width="120"></el-table-column>
      <el-table-column prop="normalIdentityNum" label="体温正常人数" width="120"></el-table-column>
    </el-table>
  </div>
</template>
<script>
import Constant from "@/constant";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";
import schoolStatApi from "@/api/base/schoolStat";
import pageUtil from "@/utils/page";

export default {
  name:'baseSchoolStatList',
  data() {
    var self = this;
    var now = new Date();
    var curDateStr = now.getFullYear();

    curDateStr += "-";

    if(now.getMonth()+1<10){
      curDateStr += "0";
    }

    curDateStr += now.getMonth()+1;

    curDateStr += "-";

    if(now.getDate()+1<10){
      curDateStr += "0";
    }

    curDateStr += now.getDate();

    return {
      ruleValidate: {
        parentId: [
            { required: true, message: "上级单位为必填项!", trigger: "blur" },
        ],
        queryDate:[
            { required: true, message: "查询日期为必填项!", trigger: "blur" },
        ]
      },
      queryModel: {
        parentId: "",
        queryDate: curDateStr
      },
      loading: false,
      tableData: [],
      companyList: [],
      tableHeight: 400
    };
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyList = jsonData.data;
      }
    });
  },
  methods: {
    search() {
      var self = this;

        this.$refs["queryForm"].validate((valid) => {
        if (valid) {
            self.loading = true;

            var formData = new FormData();

            if(self.queryModel.parentId!=null){
                formData.append("parentId", self.queryModel.parentId);
            }

            if(self.queryModel.queryDate!=null){
                formData.append("queryDate", self.queryModel.queryDate);
            }

            schoolStatApi
            .measureTemperatureStat(formData)
            .then(function(response) {
                self.loading = false;
                var jsonData = response.data;

                self.tableData = jsonData.data;
            })
            .catch(error => {
                self.loading = false;
            });
        }
        });
    },
    downloadCSV() {
      //批量导出
      var BOM = "\uFEFF";

      var csv = [];
      var titles = ["序号","学校名称","安装设备数","设备本日在线数","设备当前在线数","学生人数","已测温学生人数","今日访客人次","体温正常人次","今日已识别人数","体温正常人数"];

      csv.push(titles.join("\t,"));

      this.tableData.forEach((item)=>{
        var arr = [];
        
        arr.push(csv.length);
        arr.push(item.name);
        arr.push(item.deviceNum);
        arr.push(item.deviceOnlineNum);
        arr.push(item.deviceNowOnlineNum);
        arr.push(item.studentNum);
        arr.push(item.detectStudentNum);
        arr.push(item.visitorNum);
        arr.push(item.normalVisitorNum);
        arr.push(item.identityNum);
        arr.push(item.normalIdentityNum);

        csv.push(arr.join(","));
      });

      var csvStr = csv.join("\n");

      var link = document.createElement("a");
      link.setAttribute("href","data:attachment/csv;charset=utf-8," + BOM + encodeURIComponent(csvStr));
      link.setAttribute("target","_blank");
      link.setAttribute("download","导出报表.csv");
      link.click();
    }
  },
  mounted: function() {
    setTimeout(()=>{
      this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el) + 40;
    },1000);
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>